<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-start content-container">
            <img src="../assets/img/benefits_of_msm_left.png" />
            <div style="margin-top: 40px;">
              <h1 style="font-size: 24px">Some of the best features of Mane Street Market App!</h1>
              <p>Saves time in searching for horses for sale and equestrian service providers</p>
              <p>Simplifies searches based on location</p>
              <p>Streamlines planning for horse buying trips</p>
              <p>Increases exposure for horses for sale and service providers</p>
              <p>Multiplies marketing efforts</p>
              <p>MSM mobile app allows users to search for horses and service providers anywhere, anytime</p>
              <div class="app-stores-container">
                <a href="https://apps.apple.com/us/app/id1552998491" target="_blank">
                  <img src="../assets/img/appstore_button.png" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.joshyap.manestreetmarket" target="_blank">
                  <img src="../assets/img/playstore_button.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';

export default {
  components: {BaseLayout, SubHeader},
  mounted() {
    document.title = 'Benefits of MSM - Mane Street Market';
    window.scrollTo(0, 0);
  }
}
</script>

<style lang="scss" scoped>
  .content-container > * {
    width: 50%;
  }

  .content-container > img {
    padding: 64px;
  }

  .app-stores-container {
    display: flex;
    gap: 16px;
    width: 100%;

    a {
      flex: 1;
      max-width: 180px;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    .content-container > * {
      width: 100%;
    }

    .content-container > img {
      padding: 0;
    }

    .content-container {
      flex-direction: column;
    }
  }
</style>